import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSET_CLASSES,
  AssetClassOptions,
  fetchDownloadMarketHistoryForCsv,
  fetchLastOpenPrices,
  LastOpenPricesType,
  MarketHistory,
  MarketHoursType,
  Order,
  REGIONS,
  selectors,
  updateAssetClassOptions,
  updateMarketHours,
} from 'state';
import {
  Button,
  Card,
  Empty,
  Input,
  InputNumber,
  Select,
  Table,
  Typography,
} from 'antd';
import {
  marketHistoryCsvFetching,
  marketHoursFetched,
  marketHoursItems,
  // underlyingAssetsFetching,
} from '../../../state/app/selectors';
import { formatters, useModal } from 'services';
import CreateAssetModal from './AssetsTab/CreateAssetModal';
import CreateGeographyModal from './CreateGeographyModal';
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import data from './index';
import { CSVLink } from 'react-csv';

const DownloadMarketHistory: React.FC = () => {
  const dispatch = useDispatch();

  const lastOpenPricesData = useSelector(selectors.lastOpenPrices);
  // const fetching = useSelector(selectors.lastOpenPricesFetching);
  // const fetching = useSelector(selectors.underlyingAssetsFetching);
  const fetching = useSelector(selectors.marketHistoryCsvFetching);
  const marketHistory = useSelector(selectors.marketHistoryCsv);
  const header = useSelector(selectors.lastOpenPricesHeader);
  const assets = useSelector(selectors.underlyingAssetsList);
  const [selectedAsset, setSelectedAsset] = useState<number | null>(null);

  // Function to handle button click and dispatch the action
  const handleDownloadCSV = () => {
    dispatch(fetchLastOpenPrices({}));
  };

  const handleSelectChange = (asset_id: number) => {
    setSelectedAsset(asset_id);
    dispatch(fetchDownloadMarketHistoryForCsv({ asset_id: asset_id }));
  };

  const csvData = useMemo(() => {
    // const titles = csvColumns.map(col => col.title);
    const titles = [
      'id',
      'asset_ric',
      'timestamp',
      'frequency',
      'bid',
      'ask',
      'open_price',
      'off_close',
    ];

    const body = marketHistory.map((asset, index) => {
      return titles.map(title => {
        const value = asset[title as keyof typeof asset];
        return value;
      });
    });
    body.unshift(titles as string[]);
    return body;
  }, [marketHistory]);

  return (
    <Card>
      <Typography.Title level={4}>Download Market History</Typography.Title>
      <Select
        loading={!assets.length}
        showSearch
        allowClear
        optionFilterProp="children"
        style={{ width: '50%' }}
        onChange={val => handleSelectChange(Number(val))}
        notFoundContent={'Loading assets'}
      >
        {assets.map(asset => {
          return (
            <Select.Option key={asset.id} value={asset.id}>
              {asset.ric}
            </Select.Option>
          );
        })}
      </Select>
      {/*<Button*/}
      {/*  disabled={selectedAsset === null}*/}
      {/*  type="primary"*/}
      {/*  // onClick={handleDownloadCSV}*/}
      {/*  style={{ marginBottom: 16, marginLeft: 10 }}*/}
      {/*  loading={fetching}*/}
      {/*>*/}
      {/*  Download .csv*/}
      {/*</Button>*/}
      <Button
        disabled={selectedAsset === null}
        loading={fetching}
        type="primary"
        style={{ marginBottom: 16, marginLeft: 10 }}
      >
        <CSVLink
          filename={'mhs'}
          data={csvData}
          // disabled={selectedAsset === null}
          // loading={fetching}
        >
          Download .csv
        </CSVLink>
      </Button>
    </Card>
  );
};

export default DownloadMarketHistory;
