import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ASSET_CLASSES,
  AssetClassOptions,
  fetchLastOpenPrices,
  LastOpenPricesType,
  MarketHoursType,
  Order,
  selectors,
  updateAssetClassOptions,
  updateMarketHours,
} from 'state';
import { Button, Empty, Input, InputNumber, Table, Typography } from 'antd';
import {
  marketHoursFetched,
  marketHoursItems,
} from '../../../state/app/selectors';
import { useModal } from 'services';
import CreateAssetModal from './AssetsTab/CreateAssetModal';
import CreateGeographyModal from './CreateGeographyModal';
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import data from './index';

const FirstOpenPriceTable: React.FC = () => {
  const dispatch = useDispatch();

  const lastOpenPricesData = useSelector(selectors.lastOpenPrices);
  const fetching = useSelector(selectors.lastOpenPricesFetching);
  const header = useSelector(selectors.lastOpenPricesHeader);

  const columns: ((
    | ColumnGroupType<LastOpenPricesType>
    | ColumnType<LastOpenPricesType>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'Geography',
      dataIndex: 'geography',
      sorter: (a: LastOpenPricesType, b: LastOpenPricesType) =>
        a.geography.localeCompare(b.geography),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Ric',
      dataIndex: 'ric',
    },
    {
      title: `${header[2] || 'today'}`,
      dataIndex: 'day0',
    },
    {
      title: `${header[3] || 'prev. day'}`,
      dataIndex: 'day1',
    },
    {
      title: `${header[4] || 'prev. day - 1'}`,
      dataIndex: 'day2',
    },
    {
      title: `${header[5] || 'prev. day - 2'}`,
      dataIndex: 'day3',
    },
  ];

  // Function to handle button click and dispatch the action
  const handleFetchOpenPrices = () => {
    dispatch(fetchLastOpenPrices({}));
  };

  const noData = (
    <Empty
      description={
        <Typography.Text type={'secondary'}>
          Load on demand, takes a while
        </Typography.Text>
      }
    />
  );

  const noDataBehaviour = {
    emptyText: noData,
  };

  return (
    <div>
      <Button
        type="primary"
        onClick={handleFetchOpenPrices}
        style={{ marginBottom: 16 }}
        loading={fetching}
      >
        Fetch Open Prices
      </Button>
      <Typography.Title level={4}>Open price arrival times</Typography.Title>
      {/*<div>*/}
      {/*  <InfoCircleOutlined style={{ marginRight: 10, color: 'orange' }} />*/}
      {/*  <Typography.Text type={'secondary'}>*/}
      {/*    Hours expressed in UTC*/}
      {/*  </Typography.Text>*/}
      {/*</div>*/}
      <Table
        style={{ marginTop: 63, maxWidth: 650 }}
        dataSource={lastOpenPricesData}
        columns={columns}
        bordered
        locale={noDataBehaviour}
        pagination={{ pageSize: 100 }}
      />
    </div>
  );
};

export default FirstOpenPriceTable;
