import React, { useEffect } from 'react';
import { Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  BUY,
  ExecutionErrorType,
  ORDER_STATUSES,
  PartialOrderType,
  retrieveExecutionErrors,
  retrievePendingMoos,
  retrieveTriedAndFailedOrders,
  selectors,
  TRANSACTION_TYPES,
  TriedAndFailedOrderType,
  retrieveExecutedMoos,
} from 'state';
import { formatters } from 'services';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import { dateWithTimeSeconds } from 'services/formatters';
import {
  moosExecutedTodaySelector,
  pendingMoosSelector,
  triedAndFailedOrdersSelector,
} from '../../../state/app/selectors';

const { Title } = Typography;

const MoosExecutedToday: React.FC = () => {
  const dispatch = useDispatch();
  const moosExecutedToday = useSelector(selectors.moosExecutedTodaySelector);
  const moosExecutedTodayList = Object.values(moosExecutedToday.items);

  useEffect(() => {
    if (!moosExecutedToday.fetched) {
      dispatch(retrieveExecutedMoos({}));
    }
  }, []);

  const columns: ((
    | ColumnGroupType<PartialOrderType>
    | ColumnType<PartialOrderType>
  ) & {
    dataIndex: string;
  })[] = [
    {
      title: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Player',
      dataIndex: 'player',
    },
    {
      title: 'Asset',
      dataIndex: 'asset_name',
    },
    {
      title: 'Type',
      dataIndex: 'order_type',
    },
    {
      title: 'Txn.',
      dataIndex: 'transaction',
      render: (val: keyof typeof TRANSACTION_TYPES) => {
        return TRANSACTION_TYPES[val];
      },
    },
    {
      title: 'Qty.',
      dataIndex: 'quantity',
      render: (val, record) => {
        if (record.transaction === BUY) {
          return formatters.commas1Digit(val);
        } else {
          return formatters.commas1Digit(-val);
        }
      },
    },
    {
      title: 'Opn_prc',
      dataIndex: 'open_price',
      render: (val, record) => {
        return formatters.commas1Digit(val);
      },
    },
    {
      title: 'Rfntv_Opn_prc',
      dataIndex: 'first_refinitiv_open_price_of_that_day',
      render: (val, record) => {
        if (val === null || val === undefined) {
          return '--';
        }
        return formatters.commas1Digit(val);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: keyof typeof ORDER_STATUSES) => {
        return ORDER_STATUSES[status];
      },
    },
    {
      title: 'timestamp',
      dataIndex: 'timestamp',
      // width: 250,
      // defaultSortOrder: 'descend',
      sorter: (a: PartialOrderType, b: PartialOrderType) => {
        const dateA = formatters.dateWithTime(a.timestamp || '');
        const dateB = formatters.dateWithTime(b.timestamp || '');
        return dateA.localeCompare(dateB);
      },
      render: val => formatters.dateWithTime(val),
    },
    {
      title: 'executed_at',
      dataIndex: 'executed_at',
      // width: 250,
      defaultSortOrder: 'descend',
      sorter: (a: PartialOrderType, b: PartialOrderType) => {
        const dateA = formatters.dateWithTime(a.executed_at || '');
        const dateB = formatters.dateWithTime(b.executed_at || '');
        return dateA.localeCompare(dateB);
      },
      render: val => formatters.dateWithTime(val),
    },
  ];

  return (
    <div>
      <Typography.Title level={4}>
        Moos Executed in last 7 days
      </Typography.Title>
      <Table
        // style={{ width: '1000px' }}
        columns={columns}
        dataSource={moosExecutedTodayList}
        pagination={{ pageSize: 10 }}
        bordered
      />
    </div>
  );
};
// deploy
export default MoosExecutedToday;
