import { appManager, AppState } from '../manager';
import {
  arrayToIndexedObject,
  addElementToIndexedArray,
  makeAPIProp,
} from 'services/indexedObject';

import * as types from '../types';
import {
  AddInstrumentsToRunningSessions,
  AddUserToClass,
  AssetEodPreview,
  IndexFundPreview,
  RefreshAssetEod,
  RefreshIndexFund,
  Trainee,
  User,
  UnderlyingAsset,
  ExecutionErrorType,
  TriedAndFailedOrderType,
  PlayerWithDifferences,
  PartialOrderType,
  MarketHoursType,
  AssetClassOptions,
  AssetIntradayPreview,
  ExecutedMoosPartialOrderType,
  LastOpenPricesType,
  LastOpenPricesStateObjectType,
  MarketHistoryCSVType,
} from '../types';
import RefreshAssetEodModal from '../../../views/AdminDashboard/Data/AssetsTab/RefreshAssetEodModal';
import { notification } from 'antd';

export const fetchCurrencies = appManager.createApi<
  unknown,
  types.Currency[],
  AppState
>('FETCH_CURRENCIES', {
  path: '/currencies/fetch_currencies',
  method: 'GET',
  successReducer(state, result) {
    state.currencies.items = arrayToIndexedObject(result);
  },
});

export const updateCurrency = appManager.createApi<
  types.Currency,
  types.Currency,
  AppState
>('UPDATE_CURRENCY', {
  path: '/currencies/update_currency',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.currencies.items, result);
  },
});

export const updateFxForwards = appManager.createApi<
  Partial<UnderlyingAsset>,
  UnderlyingAsset,
  AppState
>('UPDATE_FX_FORWARDS', {
  path: '/admin/update_fx_forwards',
  method: 'POST',
  successReducer(state, result) {
    // console.log(result);
    state.underlying_assets.items[result.id].no_of_decimals =
      result.no_of_decimals;
    state.underlying_assets.items[result.id].corresponding_fx_asset =
      result.corresponding_fx_asset;
    // addElementToIndexedArray(state.currencies.items, result);
  },
});

// TODO: THESE SHOULD PROBABLY BE IN THE UNDERLYING ASSETS SLICE ?!

export const updateFXCross = appManager.createApi<
  Partial<types.FXCross> & { id: number },
  types.FXCross,
  AppState
>('UPDATE_FXCROSS', {
  path: '/fx_cross/:id',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.fx_cross.items, result);
    addElementToIndexedArray(state.underlying_assets.items, result);
  },
});

export const fetchFXCross = appManager.createApi<
  unknown,
  types.FXCross[],
  AppState
>('FETCH_FXCROSS', {
  path: '/fx_cross',
  method: 'GET',
  successReducer(state, result) {
    state.fx_cross.items = arrayToIndexedObject(result);
  },
});

export const createFXCross = appManager.createApi<
  types.CreateFXCross,
  types.FXCross,
  AppState
>('CREATE_FXCROSS', {
  path: '/fx_cross',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.fx_cross.items, result);
    addElementToIndexedArray(state.underlying_assets.items, result);
  },
});

export const deleteFXCross = appManager.createApi<
  { id: number },
  unknown,
  AppState
>('DELETE_FXCROSS', {
  path: '/fx_cross/:id',
  method: 'DELETE',
  successReducer(state, result, payload) {
    delete state.fx_cross.items[payload.id];
    delete state.underlying_assets.items[payload.id];
  },
});

export const fetchAssets = appManager.createApi<
  unknown,
  types.UnderlyingAsset[],
  AppState
>('FETCH_ASSETS', {
  path: '/admin/fetch_assets',
  method: 'GET',
  successReducer(state, result) {
    state.underlying_assets.items = arrayToIndexedObject(result);
    state.underlying_assets.fetched = true;
  },
});

export const fetchFxForwards = appManager.createApi<
  unknown,
  Partial<types.UnderlyingAsset>[],
  AppState
>('FETCH_FX_FORWARDS', {
  path: '/underlying_assets/list_fx_forwards',
  method: 'GET',
  successReducer(state, result) {
    // console.log(result);
    state.underlying_assets.items = arrayToIndexedObject(result);
    state.underlying_assets.fetched = true;
  },
});

export const fetchEquities = appManager.createApi<
  unknown,
  types.Equity[],
  AppState
>('FETCH_EQUITIES', {
  path: '/admin/fetch_equities',
  method: 'GET',
  successReducer(state, result) {
    state.equities.items = arrayToIndexedObject(result);
  },
});

export const updateAdminEquity = appManager.createApi<
  Partial<types.Equity>,
  types.Equity,
  AppState
>('UPDATE_ADMIN_EQUITY', {
  path: '/admin/update_admin_equity',
  method: 'POST',
  startReducer(state, props) {
    const id = props.id;
    if (typeof id !== 'number') {
      return;
    }
    const items = state.equities.items;
    items[id] = { ...items[id], ...props };
  },
  successReducer(state, result) {
    const equities = state.equities.items;
    equities[result.id] = { ...equities[result.id], ...result };
  },
});

export const fetchUnderlyingDeposits = appManager.createApi<
  unknown,
  types.DepositUnderlying[],
  AppState
>('FETCH_DEPOSITS', {
  path: '/admin/fetch_deposits',
  method: 'GET',
  successReducer(state, result) {
    state.underlying_deposits.items = arrayToIndexedObject(result);
  },
});

export const updateDeposits = appManager.createApi<
  Partial<types.DepositUnderlying>,
  types.DepositUnderlying,
  AppState
>('UPDATE_DEPOSITS', {
  path: '/admin/update_deposits',
  method: 'POST',
  startReducer(state, props) {
    const id = props.id;
    if (typeof id !== 'number') {
      return;
    }
    const items = state.equities.items;
    items[id] = { ...items[id], ...props };
  },
  successReducer(state, result) {
    addElementToIndexedArray(state.underlying_assets.items, result);
  },
});

export const updateAsset = appManager.createApi<
  Partial<types.UnderlyingAsset>,
  types.UnderlyingAsset,
  AppState
>('UPDATE_ASSET', {
  path: '/admin/update_asset',
  method: 'POST',
  startReducer(state, props) {
    const id = props.id || 0;
    const items = state.underlying_assets.items;
    items[id] = { ...items[id], ...props };
  },
  successReducer() {
    // addElementToIndexedArray(state.underlying_assets.items, result);
  },
});

export const fetchIndexFunds = appManager.createApi<
  unknown,
  types.IndexFund[],
  AppState
>('FETCH_INDEX_FUNDS', {
  path: '/admin/fetch_index_funds',
  method: 'GET',
  successReducer(state, result) {
    state.index_funds.items = arrayToIndexedObject(result);
  },
});

export const updateIndexFund = appManager.createApi<
  types.IndexFund,
  types.IndexFund,
  AppState
>('UPDATE_INDEX_FUND', {
  path: '/admin/update_index_fund',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.index_funds.items, result);
  },
});

export const fetchNews = appManager.createApi<
  { start_date: string; end_date: string },
  types.News[],
  AppState
>('FETCH_NEWS', {
  path: '/admin/fetch_news',
  method: 'GET',
  startReducer(state) {
    state.news.fetching = true;
  },
  failReducer(state) {
    state.news.fetching = false;
  },
  successReducer(state, result) {
    state.news.items = arrayToIndexedObject(result);
    state.news.fetching = false;
  },
});

export const updateNews = appManager.createApi<
  Partial<types.News>,
  types.News,
  AppState
>('UPDATE_News', {
  path: '/admin/update_news',
  method: 'POST',
  successReducer(state, result) {
    addElementToIndexedArray(state.news.items, result);
  },
});

export const computeVolatility = appManager.createApi(
  'ADMIN_COMPUTE_VOLATILITY',
  {
    path: '/admin/compute_volatility',
    method: 'POST',
    successReducer() {
      /* empty */
    },
  }
);

export const computeBeta = appManager.createApi('ADMIN_COMPUTE_BETA', {
  path: '/admin/compute_beta',
  method: 'POST',
  successReducer() {
    /* empty */
  },
});

export const deleteCorrelationValues = appManager.createApi<
  unknown,
  [number, Record<string, unknown>],
  AppState
>('ADMIN_DELETE_CORRELATION_VALUES', {
  path: '/admin/delete_correlation_values',
  method: 'POST',
  successReducer(state, result) {
    if (result) {
      state.admin.message = result[0] + ' CorrelationValues deleted';
    }
  },
});

appManager.createSocketListener<types.EquityBeta, AppState>(
  'beta_computed',
  (state, result) => {
    state.admin.betas[result.id] = result;
  }
);

appManager.createSocketListener<types.EquityVolatility, AppState>(
  'volatility_computed',
  (state, result) => {
    state.admin.volatilities[result.id] = result;
  }
);

export const retrieveAdminPlayer = appManager.createApi<
  { player_id: number; session_id?: number },
  { player: any; orders: types.Order[] },
  AppState
>('RETRIEVE_ADMIN_PLAYER', {
  path: '/sessions/:session_id/retrieve_player_admin',
  method: 'GET',
  startReducer(state, payload) {
    if (!state.players.items[payload.player_id]) return;
    state.players.items[payload.player_id].fetched_data = true;
  },
  successReducer(state, result) {
    const { player, orders } = result;

    Object.assign(state.players.items[player.id], {
      ...player,
      financial_instruments: arrayToIndexedObject(player.financial_instruments),
      fetched_success: true,
      fetched_data: true,
      retrieved_full: true,
    });

    const newOrders = arrayToIndexedObject(orders);
    state.orders.items = { ...state.orders.items, ...newOrders };
  },
});

export const buyDefaultPortfolio = appManager.createApi<
  { sessions: number[]; assets: types.Group[] },
  unknown,
  AppState
>('BUY_DEFAULT_PORTFOLIOS', {
  method: 'POST',
  path: '/sessions/buy_default_portfolio',
  successReducer() {
    return null;
  },
  failReducer() {
    return null;
  },
});

appManager.createSocketListener<types.InitialBuyStatus, AppState>(
  'buy_initial_portfolio_status',
  (state, result) => {
    state.initialPortfolioStatus[result.id] = result;
  }
);

export const listTrainers = appManager.createApi<
  unknown,
  types.Trainer[],
  AppState
>('LIST_TRAINERS', {
  path: '/trainers',
  method: 'GET',
  startReducer(state) {
    state.trainers.waiting = true;
  },
  successReducer(state, result) {
    result.forEach(trainer => {
      state.trainers.items[trainer.id] = trainer;
    });
    state.trainers.waiting = false;
  },
  failReducer(state) {
    state.trainers.waiting = false;
  },
});

export const setTrainerPassword = appManager.createApi<
  { trainer_id: number; password: string },
  unknown,
  AppState
>('SET_TRAINER_PASSWORD', {
  path: '/trainers/:trainer_id/set_password',
  method: 'POST',
  successReducer() {
    //
  },
});

export const deleteKlass = appManager.createApi<
  { klass_id: number },
  { klass_id: number; trainer_id: number },
  AppState
>('DELETE_KLASS', {
  path: '/klasses/delete_klass',
  method: 'DELETE',
  successReducer(state, result) {
    // console.log(result.klass_id);
    delete state.klasses.items[result.klass_id];
    const klass_index = state.trainers.items[
      result.trainer_id
    ].klasses.findIndex(klass => klass.id === result.klass_id);
    state.trainers.items[result.trainer_id].klasses.splice(klass_index, 1);
  },
});

export const deleteTrainee = appManager.createApi<
  { trainee_id: number },
  { trainee_id: number; klass_id: number },
  AppState
>('DELETE_TRAINEE', {
  path: '/users/delete_trainee',
  method: 'DELETE',
  successReducer(state, result) {
    // console.log(result.trainee_id);
    const klass = state.klasses.items[result.klass_id];
    // console.log(klass);
    // console.log(klass.trainees);
    const traineeIndex = klass.trainees.findIndex(
      trainee => trainee.id === result.trainee_id
    );
    klass.trainees.splice(traineeIndex, 1);
  },
});

export const listMarketHistory = appManager.createApi<
  {
    underlying_asset: number;
    frequency: number;
    timestamp_range: string[];
  },
  types.AdminMarketHistory[],
  AppState
>('LIST_MARKET_HISTORY', {
  path: '/market_history/list_history',
  method: 'POST',
  startReducer(state) {
    state.market_history.waiting = true;
  },
  successReducer(state, result) {
    state.market_history.items = {};
    result.forEach(mh => {
      state.market_history.items[mh.id] = mh;
    });
    state.market_history.waiting = false;
  },
  failReducer(state) {
    state.market_history.waiting = false;
  },
});

export const requestIndexFundPreview = appManager.createApi<
  {
    index_fund_id: number;
  },
  IndexFundPreview,
  AppState
>('PREVIEW_INDEX_FUND', {
  path: '/admin/preview_index_fund_eod',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    state.index_fund_preview = result;
  },
  failReducer(state, result) {
    state.index_fund_preview = { data: [], ric: '' };
  },
});

export const requestAssetEodPreview = appManager.createApi<
  {
    asset_id: number;
  },
  AssetEodPreview,
  AppState
>('PREVIEW_ASSET_EOD', {
  path: '/admin/preview_asset_eod',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    state.asset_eod_preview = result;
  },
  failReducer(state, result) {
    state.asset_eod_preview = { data: [], ric: '', is_yield: false };
  },
});

export const requestAssetIntradayPreview = appManager.createApi<
  {
    asset_id: number;
  },
  AssetIntradayPreview,
  AppState
>('PREVIEW_ASSET_INTRADAY', {
  path: '/admin/preview_asset_intraday',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    state.asset_intraday_preview = result;
  },
  failReducer(state, result) {
    state.asset_intraday_preview = { data: [], ric: '' };
  },
});

export const refreshIndexFundDataFromRefinitiv = appManager.createApi<
  RefreshIndexFund,
  unknown,
  AppState
>('REFRESH_INDEX_FUND_DATA_FROM_REFINITIV', {
  path: '/admin/refresh_index_fund_data_from_refinitiv',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    // state.index_fund_preview = result;
  },
  failReducer(state, result) {
    // state.index_fund_preview = { data: [], ric: '' };
  },
});

export const refreshAssetEodFromRefinitiv = appManager.createApi<
  RefreshAssetEod,
  unknown,
  AppState
>('REFRESH_ASSET_EOD_FROM_REFINITIV', {
  path: '/admin/refresh_asset_eod_from_refinitiv',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    // state.index_fund_preview = result;
  },
  failReducer(state, result) {
    // state.index_fund_preview = { data: [], ric: '' };
  },
});

export const uploadNews = appManager.createApi<
  { news_file: unknown },
  unknown,
  AppState
>('UPLOAD_NEWS', {
  path: '/admin/upload_news',
  method: 'POST',
  successReducer(state, result) {
    // pass
  },
  failReducer(state, result) {
    // state.index_fund_preview = { data: [], ric: '' };
  },
});

export const addUserToClass = appManager.createApi<
  AddUserToClass,
  {
    trainee: Trainee;
    klass_id: number;
  },
  AppState
>('ADD_USER_TO_CLASS', {
  path: '/klasses/add_new_user_to_klass',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    state.klasses.items[result.klass_id].trainees[result.trainee.id] =
      result.trainee;
    // state.index_fund_preview = result;
  },
  failReducer(state, result) {
    // state.index_fund_preview = { data: [], ric: '' };
  },
});

export const addInstrumentsToRunningSessions = appManager.createApi<
  AddInstrumentsToRunningSessions,
  unknown,
  AppState
>('ADD_INSTRUMENTS_TO_RUNNING_SESSIONS', {
  path: '/admin/add_instruments_to_running_sessions',
  method: 'POST',
  successReducer(state, result) {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = result.benchmark_returns;
    // console.log(result);
    // state.klasses.items[result.klass_id].trainees[result.trainee.id] =
    //   result.trainee;
    // state.index_fund_preview = result;
  },
  failReducer(state, result) {
    // state.index_fund_preview = { data: [], ric: '' };
  },
});

export const clearIndexFundPreview = appManager.createLocalEvent(
  'clear_index_fund_preview',
  state => {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = undefined;
    state.index_fund_preview = undefined;
  }
);

export const clearAssetEodPreview = appManager.createLocalEvent(
  'clear_asset_eod_preview',
  state => {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = undefined;
    state.asset_eod_preview = undefined;
  }
);

export const clearAssetIntradayPreview = appManager.createLocalEvent(
  'clear_asset_intraday_preview',
  state => {
    // const session = state.sessions.items[state.active_session];
    // session.benchmark_preview = undefined;
    state.asset_intraday_preview = undefined;
  }
);

export const retrieveExecutionErrors = appManager.createApi<
  unknown,
  { errors: ExecutionErrorType[] },
  AppState
>('RETRIEVE_EXECUTION_ERRORS', {
  path: '/errors/get_execution_errors',
  method: 'GET',
  startReducer(state, payload) {
    state.errors_tab.execution_errors = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.execution_errors = makeAPIProp(result.errors);
    state.errors_tab.execution_errors.fetched = true;
    state.errors_tab.execution_errors.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.execution_errors.fetching = false;
    state.errors_tab.execution_errors.fetched = false;
  },
});

export const retrieveTriedAndFailedOrders = appManager.createApi<
  unknown,
  { err_orders: TriedAndFailedOrderType[] },
  AppState
>('RETRIEVE_TRIED_AND_FAILED_ORDERS', {
  path: '/errors/get_pending_orders_with_one_or_more_execution_tries',
  method: 'GET',
  startReducer(state, payload) {
    state.errors_tab.tried_and_failed_orders = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.tried_and_failed_orders = makeAPIProp(result.err_orders);
    state.errors_tab.tried_and_failed_orders.fetched = true;
    state.errors_tab.tried_and_failed_orders.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.tried_and_failed_orders.fetching = false;
    state.errors_tab.tried_and_failed_orders.fetched = false;
  },
});

export const retrievePlayersWithDiffNumbers = appManager.createApi<
  { difference: number },
  { diff_players: PlayerWithDifferences[] },
  AppState
>('RETRIEVE_PLAYERS_WITH_DIFF_NUMBERS', {
  path: '/errors/get_players_with_differences',
  method: 'POST',
  startReducer(state, payload) {
    state.errors_tab.players_with_differences = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.players_with_differences = makeAPIProp(
      result.diff_players
    );
    state.errors_tab.players_with_differences.fetched = true;
    state.errors_tab.players_with_differences.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.players_with_differences.fetching = false;
    state.errors_tab.players_with_differences.fetched = false;
  },
});

export const retrievePendingMoos = appManager.createApi<
  unknown,
  { pending_moos: PartialOrderType[] },
  AppState
>('RETRIEVE_PENDING_MOOS', {
  path: '/errors/get_pending_moos',
  method: 'GET',
  startReducer(state, payload) {
    state.errors_tab.pending_moos = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.pending_moos = makeAPIProp(result.pending_moos);
    state.errors_tab.pending_moos.fetched = true;
    state.errors_tab.pending_moos.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.pending_moos.fetching = false;
    state.errors_tab.pending_moos.fetched = false;
  },
});

export const retrieveExecutedMoos = appManager.createApi<
  unknown,
  { moos: ExecutedMoosPartialOrderType[] },
  AppState
>('RETRIEVE_EXECUTED_MOOS', {
  path: '/errors/get_moos_executed_today',
  method: 'GET',
  startReducer(state, payload) {
    state.errors_tab.moos_executed_today = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.moos_executed_today = makeAPIProp(result.moos);
    state.errors_tab.moos_executed_today.fetched = true;
    state.errors_tab.moos_executed_today.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.moos_executed_today.fetching = false;
    state.errors_tab.moos_executed_today.fetched = false;
  },
});

export const retrievePendingSlTps = appManager.createApi<
  unknown,
  { sltps: PartialOrderType[] },
  AppState
>('RETRIEVE_PENDING_SLTPS', {
  path: '/errors/get_all_pending_sl_tp',
  method: 'GET',
  startReducer(state, payload) {
    state.errors_tab.pending_sl_tps = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.errors_tab.pending_sl_tps = makeAPIProp(result.sltps);
    state.errors_tab.pending_sl_tps.fetched = true;
    state.errors_tab.pending_sl_tps.fetching = false;
  },
  failReducer(state) {
    state.errors_tab.pending_sl_tps.fetching = false;
    state.errors_tab.pending_sl_tps.fetched = false;
  },
});

export const fetchMarketHours = appManager.createApi<
  unknown,
  { market_hours: MarketHoursType[] },
  AppState
>('FETCH_MARKET_HOURS', {
  path: '/market_hours/get_market_hours',
  method: 'GET',
  startReducer(state, payload) {
    state.market_hours = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    // console.log('market hours result: ', result);
    state.market_hours = makeAPIProp(result.market_hours);
    state.market_hours.fetched = true;
    state.market_hours.fetching = false;
  },
  failReducer(state) {
    state.market_hours.fetching = false;
    state.market_hours.fetched = false;
  },
});

export const createMarketHours = appManager.createApi<
  Partial<MarketHoursType>,
  MarketHoursType,
  AppState
>('CREATE_MARKET_HOURS', {
  path: '/market_hours/create_market_hours',
  method: 'POST',
  // startReducer(state, payload) {},
  successReducer(state, result) {
    const items = state.market_hours.items;
    items[result.id] = result;
  },
});

export const updateMarketHours = appManager.createApi<
  { market_hours: { [key: number]: MarketHoursType } },
  // types.AssetClassOptions,
  types.MarketHoursType[],
  AppState
>('UPDATE_MARKET_HOURS', {
  path: '/market_hours/edit_market_hours',
  method: 'POST',
  successReducer(state, result) {
    // response received on socket at updated_asset_class_options
    state.market_hours = makeAPIProp(result);
  },
});

appManager.createSocketListener<{ failed_item: string; err: string }, AppState>(
  // TODO: doesn't work
  'market_hours_update_err',
  (state, result) => {
    notification.error({
      message: `Update failed for ${result.failed_item}`,
      description: result.err,
    });
  }
);

appManager.createSocketListener<
  { utc_now: string; geography: string },
  AppState
>('executing_moo_moc_admin_notification', (state, result) => {
  console.log('result: ', result);
  notification.success({
    message: `EXECUTING ${result.geography}`,
    description: `UTC now: ${result.utc_now}`,
    duration: 20,
  });
});

export const fetchLastOpenPrices = appManager.createApi<
  unknown,
  LastOpenPricesStateObjectType,
  AppState
>('FETCH_LAST_OPEN_PRICES', {
  path: '/market_hours/get_first_open_prices',
  method: 'GET',
  startReducer(state, payload) {
    state.last_open_prices = {
      items: {},
      header: [],
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.last_open_prices.items = arrayToIndexedObject(result.rows);
    state.last_open_prices.header = result.header;
    state.last_open_prices.fetched = true;
    state.last_open_prices.fetching = false;
  },
  failReducer(state) {
    state.last_open_prices.fetching = false;
    state.last_open_prices.fetched = false;
  },
});

export const fetchLastClosePrices = appManager.createApi<
  unknown,
  LastOpenPricesStateObjectType,
  AppState
>('FETCH_LAST_CLOSE_PRICES', {
  path: '/market_hours/get_first_close_prices',
  method: 'GET',
  startReducer(state, payload) {
    state.last_close_prices = {
      items: {},
      header: [],
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.last_close_prices.items = arrayToIndexedObject(result.rows);
    state.last_close_prices.header = result.header;
    state.last_close_prices.fetched = true;
    state.last_close_prices.fetching = false;
  },
  failReducer(state) {
    state.last_close_prices.fetching = false;
    state.last_close_prices.fetched = false;
  },
});

export const fetchDownloadMarketHistoryForCsv = appManager.createApi<
  { asset_id: number },
  { rows: MarketHistoryCSVType[] },
  AppState
>('FETCH_MARKET_HISTORY_CSV_DOWNLOAD', {
  path: '/market_hours/get_market_history_csv_download',
  method: 'POST',
  startReducer(state, payload) {
    state.market_history_download_csv = {
      items: {},
      fetching: true,
      fetched: false,
    };
  },
  successReducer(state, result) {
    state.market_history_download_csv.items = arrayToIndexedObject(result.rows);
    state.market_history_download_csv.fetched = true;
    state.market_history_download_csv.fetching = false;
  },
  failReducer(state) {
    state.market_history_download_csv.fetching = false;
    state.market_history_download_csv.fetched = false;
  },
});
