import React, { useEffect } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveExecutionErrors, selectors } from 'state';
import ExecutionErrors from './ExecutionErrors';
import TriedAndFailedOrders from './TriedAndFailedOrders';
import PlayersWithDifferences from './PlayersWithDifferences';
import PendingMoos from './PendingMoos';
import MoosExecutedToday from './MoosExecutedToday';
import PendingSLsTPs from './PendingSLsTPs';

const { Title } = Typography;

const Errors: React.FC = () => {
  return (
    <div>
      <Row gutter={40}>
        <Col span={16}>
          <ExecutionErrors />
        </Col>
        <Col span={8}>
          <TriedAndFailedOrders />
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={10}>
          <PendingMoos />
        </Col>
        <Col span={14}>
          <MoosExecutedToday />
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={8}>
          {' '}
          <PlayersWithDifferences />
        </Col>
        <Col span={16}>
          {' '}
          <PendingSLsTPs />
        </Col>
      </Row>
    </div>
  );
};
// deploy
export default Errors;
