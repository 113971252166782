import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Button, Col, Input, InputNumber, Row, Table, Typography } from 'antd';
import MarketHoursEditableTable from './MarketHoursEditableTable';
import PendingMoos from '../Errors/PendingMoos';
import MoosExecutedToday from '../Errors/MoosExecutedToday';
import { Button, Col, Row } from 'antd';
import FirstOpenPriceTable from './FirstOpenPriceTable';
import FirstClosePriceTable from './FirstClosePriceTable';
import DownloadMarketHistory from './DownloadMarketHistory';

// type State = {
//   [id: number]: MarketHoursType;
// };

// type Row = {
//   geography: string;
//   open_hours: string;
//   closing_hours: string;
//   metals_friday_close: string | undefined;
//   metals_sunday_open: string | undefined;
// };

// type DataSource = {
//   id: number;
//   row: Row;
// };

const GeographyMarketHoursTab: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Row gutter={40}>
        <Col span={8}>
          <MarketHoursEditableTable />
        </Col>
        <Col span={8}>
          <FirstOpenPriceTable />
          <br />
          <DownloadMarketHistory />
        </Col>
        <Col span={8}>
          <FirstClosePriceTable />
        </Col>
        {/*<Col span={8}></Col>*/}
      </Row>
    </div>
  );
};

export default GeographyMarketHoursTab;
